import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import { navigate } from "@reach/router";
import { urlServer } from "../utils.js";
import _ from "lodash";
const { htmlToText } = require("html-to-text");
class AuthorTemplate extends Component {
  constructor (props) {
    super(props);
    this.state = {
      currentUser: props.data.wpUser,
      lang: props.pageContext.lang
    };
    this.createSchema = this.createSchema.bind(this);
  }

  createSchema (user, lang) {
    const appJsonAux = [];
    let authorSchema = "";
    if (user.name !== null) {
      authorSchema = {
        "@type": "Person",
        name: user.name,
        image: user.avatar.url,
        description: user.description,
        url: urlServer + lang + "/author/" + user.slug
      };
    }
    //email: (user.email == null ? "" : user.email)
    const userSchemaString = JSON.stringify(authorSchema);

    appJsonAux.push(userSchemaString);

    // console.log("JSON", appJsonAux);

    return appJsonAux;
  }

  render () {
    var nameCat;
    var cat;
    const user = this.props.data.wpUser;
    const posts = this.props.data.allWpPost;
    const lang = this.props.pageContext.idioma;
    if ( user == null ) {
        navigate(`/${lang}`);
    }
    const slugAuthor = "/" + lang + "/author/" + user.slug;
    const seo = user.seo;
    const linksOfInterest= seo.social;
    let visualizeLinks=false;
    for(var key in linksOfInterest){
      if(linksOfInterest[key] !== ""){
        visualizeLinks=true;
        break;
      }
    }
    const app_json = this.createSchema(user, lang);
    const alternates = [];
    return <Layout currentPage={user.name} langPage={lang} alternates={alternates}>
      <SEO
        title={user.name + " | Autor de Billage"}
        description={seo.metaDesc}
        canonical={slugAuthor}
        robotsNofollow="follow"
        robotsNoindex="index"
        // openGraphSite={seo.opengraphSiteName}
        // openGraphType={seo.opengraphType}
        openGraphUrl={slugAuthor}
        openGraphSite="Billage"
        openGraphType="Author"
        openGraphImg={
          seo.opengraphImage !== null
            ? seo.opengraphImage.sourceUrl
            : "/images/logo-billage-default-social-share.jpg"
        }
        openGraphImgWidth={
          seo.opengraphImage !== null
            ? seo.opengraphImage.mediaDetails.width
            : 300
        }
        openGraphImgHeight={
          seo.opengraphImage !== null
            ? seo.opengraphImage.mediaDetails.height
            : 300
        }
        lang={lang}
        twitterImg={
          seo.twitterImage !== null
            ? seo.twitterImage.sourceUrl
            : "/images/logo-billage-default-social-share.jpg"
        }
        twitterImgHeight={
          seo.twitterImage !== null ? seo.twitterImage.mediaDetails.height : 300
        }
        twitterImgWidth={
          seo.twitterImage !== null ? seo.twitterImage.mediaDetails.width : 300
        }
        alternates={alternates}
        appJson={app_json}

      />
	<div className="page-blog">
      <div className="container author">
          <div className="row">
			       <header>

                <div className="info-author">

					<img src={user.avatar.url} alt="nombre"/>
					<h1>{user.name}</h1>
          <p>{user.description}</p>
          {visualizeLinks
          ?
          <div class="socialAuhor">
      	  		<h2>Enlaces de interés</h2>
      	  		<p>Puedes descubrir más sobre {user.name} visitando:</p>
      	  		<ul>
              {Object.keys(linksOfInterest).map((key) => {
                if(linksOfInterest[key] !== "") {
                  return  (<li key={key} className={key}>
                    <Link to={linksOfInterest[key]} title={key}>{key}</Link>
                   </li>);
                }
                return null;
              })}
      				</ul>
      	  </div>
          :
          null}

                </div>
              </header>
              <div className="row post-list">

                  {posts.nodes.map((post, i) => {
                    let html = post.excerpt;
                    html = html.replace("[", "");
                    html = html.replace("]", "");
                    let post_excerpt = htmlToText(html, {
                      ignoreHref: true
                    });
                    post_excerpt = _.truncate(post_excerpt, {
                      length: 170,
                      separator: /,?\.* +/
                    });

                    return (
                    <div key={post.databaseId} className="col-12 col-md-4 post-wrapper">
                                  <article key={i} className="post-item">
                        {post.categories.nodes.map(categoria => {
                          if (categoria.ancestors !== null && categoria.databaseId !== 9525 && categoria.databaseId !== 9552 && categoria.databaseId !== 9528 && categoria.databaseId !== 9531 && categoria.databaseId !== 9534 && categoria.databaseId !== 9537 && categoria.databaseId !== 9549 && categoria.databaseId !== 9540 && categoria.databaseId !== 9543 && categoria.databaseId !== 9546 && categoria.databaseId!==105 ) {
                            cat = categoria.slug;
                            nameCat = categoria.name;
                          } else {
                            // if(categoria.databaseId==102 || categoria.databaseId==99){
                            //   main_cat = categoria.slug // not used
                            // }
                          }
                          return true;
                        })}

                        <div className="header">
                        <button className="overlay" onClick={() => { location.href = `/es/blog/${post.slug}`; }}>
                            <span>Leer Más</span>
                        </button>

                        {post.featuredImage !== null &&
                                post.featuredImage.node !== null &&
                                post.featuredImage.node.localFile !== null && post.featuredImage.node.localFile.childImageSharp.fluid !== null
                                ? (
                                <Img itemProp="image"
                                  fluid={
                                    post.featuredImage.node.localFile.childImageSharp.fluid
                                  }
                                />
                            )
                          : null}
                      </div>

                            <div className="inner">
                                <aside className="infopost">
                                  <div className="author gravatar">
                                    <img
                                      src={post.author.node.avatar.url}
                                      alt={post.author.node.name}
                                      title={post.author.node.name}
                                    />
                                  </div>
                                  <div className="metadata">
                                    {nameCat}
                                    <span>|</span>
                                    {post.date}
                                  </div>
                                </aside>
                                <span className="title">
                                  <Link
                                    to={`/es/blog/${post.slug}`}
                                  >{`${post.title}`}</Link>
                                </span>
                                <Link to={`/es/blog/${post.slug}`}><p className="excerpt">{post_excerpt}</p></Link>
                            </div>
                        </article>
                    </div>
                    );
                  })}
              </div>
          </div>
      </div>
</div>
  </Layout>;
  }
}
export default AuthorTemplate;
export const AuthorQuery = graphql`
    query currentAuthor($id: String!) {
        wpUser(id: {eq: $id}) {
            email
            name
            avatar {
              url
            }
            description
            slug
            seo {
              title
              metaDesc
              canonical
              metaDesc
              opengraphTitle
              opengraphDescription
              opengraphImage {
                  altText
                  sourceUrl
                  title
                  mediaDetails {
                  height
                  width
                  }
              }
              twitterDescription
              twitterTitle
              twitterImage {
                  altText
                  mediaDetails {
                  height
                  width
                  }
                  sourceUrl
              }
              social {
                facebook
                instagram
                linkedIn
                mySpace
                pinterest
                soundCloud
                twitter
                wikipedia
                youTube
              }
            }

        }
        allWpPost (filter: {author: {node: {id: {eq: $id}}}, categories: {nodes: {elemMatch: {ancestors: {nodes: {elemMatch: {databaseId: {eq: 102}}}}}}}}){
          nodes {
            databaseId
            title
            slug
            date(formatString: "DD/MM/yyyy")
            excerpt
            locale {
              id
            }
            categories {
              nodes {
                id
                ancestors {
                  nodes {
                    parentId
                    name
                    databaseId
                    slug
                  }
                }
                databaseId
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
                srcSet
                title
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  url
                }
              }
            }
            author {
              node {
                name
                slug
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                databaseId
                name
                slug
                ancestors {
                  nodes {
                    databaseId
                    slug
                    uri
                    name
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              canonical
              metaDesc
              opengraphTitle
              opengraphType
              opengraphSiteName
              opengraphDescription
              opengraphImage {
                altText
                sourceUrl
                title
                mediaDetails {
                  height
                  width
                }
              }
              twitterDescription
              twitterTitle
              twitterImage {
                altText
                mediaDetails {
                  height
                  width
                }
                sourceUrl
              }
            }
          }
        }
    }
`;
